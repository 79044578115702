.rmwc-collapsible-list {
  width: 100%;
}

.rmwc-collapsible-list__children {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s, opacity 0.3s;
  opacity: 0;
}

.rmwc-collapsible-list--open > .rmwc-collapsible-list__children {
  opacity: 1;
}

.rmwc-collapsible-list__handle .mdc-list-item__meta {
  transition: transform 0.3s;
  user-select: none;
}

.rmwc-collapsible-list--open
  > .rmwc-collapsible-list__handle
  .mdc-list-item__meta {
  transform: rotate(90deg);
}

.rmwc-collapsible-list__children .mdc-list-item {
  padding-left: 2rem;
}
